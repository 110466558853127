<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    type?: string | undefined;
  }>(),
  {
    type: "AIRPORT",
  }
);

const { getPlaces } = useGlobalStore();
const localeRoute = useLocaleRoute();
// const { locale } = useI18n();
// const { data } = await useAsyncData<PlacesResponse>(locale.value + "_component_places_" + props.type, () =>
//   useNuxtApp().$siteFetch("/v1/places", {
//     params: {
//       "filter[type]": props.type,
//       "page[size]": 10,
//       include: "asset_photo",
//     },
//   })
// );

const places = computed(() => {
  const placesObject = getPlaces(props.type);
  return Object.values(placesObject).flat();
});
</script>

<template>
  <section class="py-8 lg:py-16">
    <h4 class="mb-4 text-3xl font-extrabold tracking-tight text-black md:text-4xl">
      <slot name="title">
        {{ $t("components.places.title") }}
      </slot>
    </h4>
    <p class="mb-8 text-base font-light text-gray md:text-xl">
      <slot name="subtitle">
        {{ $t("components.places.subtitle") }}
      </slot>
    </p>

    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-7">
      <div v-for="item in places">
        <PlaceItem :item="item" />
      </div>
    </div>
    <v-slide-group class="mt-8">
      <v-slide-group-item>
        <v-btn
          :to="localeRoute({ name: 'parkings-airports' })"
          variant="tonal"
          rounded="2xl"
          color="primary"
          class="mr-4"
        >
          {{ $t("fields.PARKING_AT_AIRPORT") }}
        </v-btn>
      </v-slide-group-item>
      <v-slide-group-item>
        <v-btn
          :to="localeRoute({ name: 'parkings-stations' })"
          variant="tonal"
          rounded="2xl"
          color="primary"
        >
          {{ $t("fields.PARKING_AT_STATION") }}
        </v-btn>
      </v-slide-group-item>
    </v-slide-group>
  </section>
</template>
